<app-header></app-header>
<section class="page-container consent-section">
  <div
    class="consent-header d-flex justify-content-center align-items-center bg-dark"
    role="banner"
    aria-labelledby="consent-title"
  >
    <h2
      id="consent-title"
      [innerHTML]="
        labels.consent_title
          | replace
            : ['{brand}', '{partner}']
            : [currentBrandName, currentPartnerName]
      "
    ></h2>
  </div>
  <div>
    <div class="d-flex justify-content-center p-3">
      <div
        class="consent-body--container p-4 d-flex flex-column justify-content-center align-items-center shadow bg-body rounded"
      >
        <img
          class="logo"
          *ngIf="logo"
          [alt]="
            labels.consent_logo_alt
              | replace : ['{partner}'] : [currentPartnerName]
          "
          [src]="logo"
        />
        <span
          class="font-subtitle p-3 text-center"
          [innerHTML]="
            labels.consent_message
              | replace
                : ['{brand}', '{partner}']
                : [currentBrandName, currentPartnerName]
          "
        ></span>
        <div class="d-flex flex-column" *ngIf="scopes?.length > 0">
          <span
          class="font-subtitle p-3" style="text-align: center;"
        >It will have access to: </span>
        <ul class="font-sub-body">
          <li *ngFor="let scope of scopes">
            <span *ngIf="!!scope">{{scope}}</span>
          </li>
        </ul>
        </div>
        <div class="d-flex flex-row justify-content-center gap-4 p-4">
          <button
            class="btn btn-transparent"
            [attr.aria-label]="labels.consent_cancel"
            (click)="cancel()"
          >
            <span>{{ labels.consent_cancel }}</span>
          </button>
          <button
            class="btn btn-primary"
            [attr.aria-label]="labels.consent_authorize"
            (click)="authorize()"
          >
            <span>{{ labels.consent_authorize }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <button type="button" class="btn btn-primary" (click)="loadLabels()">
    Cambia labels
  </button> -->
</section>
<app-footer></app-footer>
